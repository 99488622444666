import React from 'react'

import { useMediaQuery } from 'react-responsive'
import { debounce, deburr, orderBy, sortBy } from 'lodash'
import { Col, Container, Row } from 'react-bootstrap'
import queryString from 'query-string'
import { Link } from 'gatsby'

import {
  Breadcrumbs,
  DepartmentsMenu,
  DepartmentsMenuShimmer,
  DepartmentsPillsShimmer,
  Shimmer,
  StoresGrid,
} from '@interco/affiliate-shopping-ui'

import { getDepartments, getStoresByDepartmentCode } from '../../../api'
import { mediaQuery } from '../../../assets/styles/variables'
import useAsync from '../../../hooks/useAsync'
import DepartmentsRes from '../../../types/DepartmentsRes'
import StoresRes from '../../../types/StoresRes'
import * as S from './Stores.styles'
import Layout from '../../Layout'
import Seo from '../../Seo'
import { isBrowser } from '../../../utils/constants'
import StoreListItem from '../../../types/StoreListItem'
import Department from '../../../types/Department'

interface SortOptions {
  [id: string]: (storeList: StoreListItem[]) => StoreListItem[]
}

const sortFunctions: SortOptions = {
  default: (storeList: StoreListItem[]) => storeList,
  cashback: (storeList: StoreListItem[]) => orderBy(storeList, 'fullCashbackValue', 'desc'),
  alphabetical: (storeList: StoreListItem[]) => sortBy(storeList, 'name'),
}

const sortArray = [
  {
    label: 'Relevância',
    value: 'default',
  },
  {
    label: 'Loja de A - Z',
    value: 'alphabetical',
  },
  {
    label: 'Cashback',
    value: 'cashback',
  },
]

const minSearchLength = 2
const defaultSort = 'default'
const defaultCategory = 'ALL-STORES'

const Stores = () => {
  const locationSearch = isBrowser ? queryString.parse(window?.location.search) : null
  const queryStringSort = locationSearch?.sort || defaultSort
  const queryStringCategory = locationSearch?.category || defaultCategory
  const isDepartmentSelected = queryStringCategory !== defaultCategory

  const storesReq = useAsync<StoresRes>()
  const departmentsReq = useAsync<DepartmentsRes>()
  const [currentFilter, setCurrentFilter] = React.useState<Department | null>(null)
  const [currentSort, setCurrentSort] = React.useState(sortArray[0])
  const [userSearch, setUserSearch] = React.useState<string>('')
  const minSM = useMediaQuery({ query: mediaQuery.minSM })

  /**
   * Effects
   */

  // Fetch stores list
  React.useEffect(() => {
    storesReq.run(getStoresByDepartmentCode(queryStringCategory as string))
  }, [queryStringCategory])

  // Fetch departments
  React.useEffect(() => {
    departmentsReq.run(getDepartments())
  }, [])

  // Set active department filter
  React.useEffect(() => {
    departmentsReq.data?.forEach((department) => {
      if (department.code === queryStringCategory) {
        setCurrentFilter(department)
      }
    })
  }, [departmentsReq.data, queryStringCategory])

  // Set active sort
  React.useLayoutEffect(() => {
    sortArray.forEach((sortItem) => {
      if (sortItem.value === queryStringSort) {
        setCurrentSort(sortItem)
      }
    })
  }, [queryStringSort])

  // Sort stores list based on dropdown selection
  const sortedStores = React.useMemo(() => {
    if (storesReq.data) {
      return sortFunctions[queryStringSort as string]?.(storesReq.data) ?? []
    }
    return []
  }, [queryStringSort, storesReq.data])

  const filteredStores = React.useMemo(() => {
    if (userSearch.length >= minSearchLength && sortedStores?.length) {
      return sortedStores?.filter((store) => {
        const normalizedStoreName = deburr(store.name.toLowerCase())
        const normalizedSearch = deburr(userSearch.toLowerCase())
        return normalizedStoreName.includes(normalizedSearch)
      })
    }

    return sortedStores
  }, [userSearch, sortedStores])

  /**
   * Event handlers
   */

  const handleSearchInputChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setUserSearch(value)
  }, 300)

  return (
    <Layout>
      <Seo
        title="Lojas com cashback"
        description="Compre no site das suas lojas preferidas aqui e ganhe dinheiro de volta em todas as suas compras. Tem ofertas + cashback nas maiores e melhores lojas do Brasil!"
        canonical="/parceiros"
      />
      <Container>
        <>
          <Row>
            <Col xs={12}>
              <Breadcrumbs>
                {
                  [
                    <Breadcrumbs.Item label="Shopping" url="/" as={Link} />,
                    <Breadcrumbs.Item
                      label="Parceiros"
                      as={Link}
                      url={isDepartmentSelected ? '/parceiros' : ''}
                    />,
                    isDepartmentSelected ? (
                      <Breadcrumbs.Item label={currentFilter?.description as string} />
                    ) : null,
                  ].filter(Boolean) as React.ReactElement[]
                }
              </Breadcrumbs>
            </Col>
          </Row>

          {departmentsReq.isSuccess && (
            <>
              {isDepartmentSelected ? (
                <S.DepartmentTitle>{currentFilter?.description}</S.DepartmentTitle>
              ) : (
                <S.Title>Lojas Parceiras</S.Title>
              )}
            </>
          )}

          {(departmentsReq.isIdle || departmentsReq.isLoading) &&
            (isDepartmentSelected ? <S.DepartmentTitleShimmer /> : <S.TitleShimmer />)}

          <Row>
            <S.MenuCol sm={4} md={3} lg={2} disableLinks={storesReq.isLoading}>
              {departmentsReq.isSuccess && (
                <>
                  {minSM ? (
                    <DepartmentsMenu
                      title="Categorias"
                      linkTag={Link}
                      departments={departmentsReq.data || []}
                      queryStringKey="category"
                      activeItem={queryStringCategory as string}
                    />
                  ) : (
                    <S.DepartmentsPills
                      departments={departmentsReq.data || []}
                      linkTag={Link}
                      queryStringKey="category"
                    />
                  )}
                </>
              )}

              {(departmentsReq.isIdle || departmentsReq.isLoading) && (
                <S.DepartmentsShimmers>
                  {minSM ? <DepartmentsMenuShimmer /> : <DepartmentsPillsShimmer />}
                </S.DepartmentsShimmers>
              )}
            </S.MenuCol>

            <Col sm={8} md={9} lg={10}>
              {storesReq.isSuccess && (
                <>
                  <S.StoresListHeader>
                    <strong>{storesReq.data?.length} lojas</strong>
                    <S.SortDropdown title={`Ordenar por: ${currentSort.label}`} align="end">
                      {sortArray.map((sortItem) => (
                        <S.SortDropdown.Item>
                          <S.SortDropdownLink
                            to={`?${queryString.stringify({
                              ...queryString.parse(window.location.search),
                              ...{ sort: sortItem.value },
                            })}`}
                          >
                            <S.SortRadio
                              id={sortItem.value}
                              value={sortItem.value}
                              checked={sortItem.value === currentSort.value}
                            />
                            <span>{sortItem.label}</span>
                          </S.SortDropdownLink>
                        </S.SortDropdown.Item>
                      ))}
                    </S.SortDropdown>
                  </S.StoresListHeader>

                  <S.SearchInput
                    id="store-search"
                    placeholder="Pesquisar loja"
                    onChange={handleSearchInputChange}
                  />

                  <StoresGrid
                    stores={filteredStores}
                    storeUrl="/parceiro"
                    linkTag={Link}
                    sm={2}
                    md={3}
                    lg={4}
                    xl={5}
                  />
                </>
              )}

              {(storesReq.isIdle || storesReq.isLoading) && (
                <>
                  <S.StoresListHeaderShimmer>
                    <Shimmer width="5rem" height="1rem" borderRadius="0.25rem" />
                    <Shimmer width="13rem" height="2.25rem" borderRadius="2rem" />
                  </S.StoresListHeaderShimmer>
                  <Shimmer width="100%" height="3rem" />
                  <S.StoresGridShimmer sm={2} md={3} lg={4} xl={5} />
                </>
              )}
            </Col>
          </Row>
        </>
      </Container>
    </Layout>
  )
}

export default Stores
