import styled from 'styled-components'
import { Col } from 'react-bootstrap'
import { Link } from 'gatsby'

import { Input } from '@inter/inter-ui/components/Input'
import { Radio } from '@inter/inter-ui/components/Radio'
import { Shimmer } from '@interco/affiliate-shopping-ui'
import {
  Dropdown,
  DepartmentsPills as DepartmentsPillsComponent,
  StoresGridShimmer as StoresGridShimmerComponent,
} from '@interco/affiliate-shopping-ui'

import { mediaQuery } from '../../../assets/styles/variables'

export const Stores = styled.div``

export const Title = styled.h1`
  font-family: Sora;
  letter-spacing: -0.04rem;
  color: var(--gray500);
  font-size: 2.5rem;
  margin: 3rem 0;

  @media ${mediaQuery.minSM} {
    font-size: 4rem;
    margin: 4.5rem 0;
  }
`

export const MenuCol = styled(Col)<{ disableLinks: boolean }>`
  a {
    pointer-events: ${(props) => (props.disableLinks ? 'none' : '')};
  }

  @media ${mediaQuery.minSM} {
    margin-top: 0.5625rem;
  }
`

export const DepartmentsPills = styled(DepartmentsPillsComponent)`
  margin-bottom: 0.5rem;
`

export const SearchInput = styled(Input)`
  width: 100%;
`

export const StoresListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

export const SortDropdown = styled(Dropdown)`
  border-radius: 2rem;
  border: 1px solid var(--gray200);

  button {
    padding: 0.5rem 1rem;
  }

  .dropdown-menu {
    padding: 0 1rem;

    & > * {
      padding: 0;
    }
  }
`

export const SortRadio = styled(Radio)`
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-right: 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const SortDropdownLink = styled(Link)`
  color: var(--gray400) !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.75rem 0;

  &:hover {
    color: var(--gray500) !important;
    text-decoration: none;
  }
`

export const DepartmentBanner = styled.img`
  width: 100%;
  height: 19vw;
  margin: 1rem 0 0;
  border-radius: 0.5rem;

  @media ${mediaQuery.minSM} {
    border-radius: 1.5rem;
    height: 105px;
    object-fit: contain;
  }

  @media ${mediaQuery.minMD} {
    height: 143px;
  }

  @media ${mediaQuery.minLG} {
    height: 192px;
  }

  @media ${mediaQuery.minXL} {
    height: 240px;
  }
`

export const DepartmentTitle = styled.h1`
  font-family: Sora;
  color: var(--gray500);
  font-size: 1.5rem;
  margin: 1rem 0 2.5rem;

  @media ${mediaQuery.minSM} {
    font-size: 2.5rem;
    margin: 1rem 0 3rem;
  }
`

/**
 * Shimmer styles
 */

export const TitleShimmer = styled(Shimmer)`
  margin: 3rem 0;
  width: 21rem;
  height: 3.125rem;

  @media ${mediaQuery.minSM} {
    margin: 4.5rem 0;
    width: 30rem;
    height: 4.8rem;
  }
`

export const DepartmentsShimmers = styled.div`
  margin-bottom: 1rem;
`

export const StoresGridShimmer = styled(StoresGridShimmerComponent)`
  margin-top: 1.625rem;
`

export const StoresListHeaderShimmer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

export const DepartmentTitleShimmer = styled(Shimmer)`
  color: var(--gray500);
  height: 1.8rem;
  width: 16rem;
  margin: 0 0 2.5rem;

  @media ${mediaQuery.minSM} {
    width: 30rem;
    height: 3rem;
    margin: 1rem 0 3rem;
  }
`
